var $util = (function () {

  function zeroFill(number, width) {
    width -= number.toString().length;
    if (width > 0) {
      return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
    }
    return number;
  }

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0]  = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  function replaceNumber(num, target, class_prefix) {
    var $tag   = $('<b></b>'),
        $nums  = document.createDocumentFragment(),
        prefix = class_prefix || 'n';

    if (typeof num === 'undefined') {
      return false;
    }

    $.each(num.toString().split(''), function () {
      $tag.clone().addClass(prefix + this).appendTo($nums);
    });

    $(target).empty().append($nums);
  }

  function log() {
    if (console) {
      Function.prototype.apply.call(console.log, console, arguments);
    }
  }

  function setFontSize() {
    var ww = $(document).width(),
        fs = ww / 30;

    $('html').css('font-size', fs + 'px');
    return this;
  }
  return {
    zeroFill: zeroFill,
    numberWithCommas: numberWithCommas,
    replaceNumber: replaceNumber,
    log: log,
    setFontSize: setFontSize
  };
}());


var $ui = (function () {

  var currentLang;

  function modal(show) {
    if (show) {
      $('#modalShadow').show()
    } else {
      $('#modalShadow').hide()
    }
  }
 
  function _popup(header, msg, fn, class_name) {
    var $pop    = $('#popYN'),
        $shadow = $('#modalShadow');

    if (header == 'close' && !msg) {
      $shadow.hide();
      $pop.hide();
      return;
    }

    if (class_name !== '') {
      $pop.addClass(class_name);
    }
    if (class_name == 'confirm'){
      $pop.find('.btn_cancel').show();
    } else {
      $pop.find('.btn_cancel').hide();
    }
    $pop.find('.desc').html(msg);
    $pop.find('.popup__btn > .popup__btn-item').one('click', function (e) {
      var $this = $(this);
      $shadow.hide();
      $pop.hide();
      $pop.removeClass('confirm');
      if ($.isFunction(fn)) {
        fn(!!$this.data('value'));
        $pop.find('a').unbind('click');
      }
      e.preventDefault();
    });

    $shadow.show();
    $pop.show();
  } 
  function inforPopup(sw) {
    var $rule = $('#rule'),
        $notice = $('#notice'),
        $info = $('#info'),
        $infoitem = $('#infoitem'),
        $shadow = $('#modalShadow')
    if (sw === 'notice') {
      modal(true);
      $notice.show().one('click', function () {
        modal(false)
        $(this).hide()
      })
    } else if (sw === 'rule'){
      modal(true);
      $rule.show().one('click', function () {
        modal(false)
        $(this).hide()
      })
    } else if (sw === 'infoitem'){
      modal(true);
      $infoitem.show().one('click', function () {
        modal(false)
        $(this).hide()
      })
    } else {
      modal(true);
      $info.addClass(sw)     
      $info.show().one('click', function () {
        modal(false) 
        $(this).hide()
      })
    
    }
    $shadow.show().one('click', function(){
      modal(false);
      $rule.hide();
      $notice.hide();
      $infoitem.hide();
      $info.hide().removeClass(sw);
    })
  }
  function resultPopup(no, currentPos) {
    var $shadow = $('#modalShadow'),
        $reward = $('#popResult');
        $shadow.show();
        $reward.show();

    if (!currentPos) currentPos = 0;
      $reward.find('.desc').html(no);
      $reward.find('.btn_confirm').one('click', function () {     
        $shadow.hide();
        $reward.hide();
        return false;
      });
    $shadow.one('click', function () {     
      $shadow.hide();
      $reward.hide();
      return false;
     });
    return false;
  }
  function alertPopup(idx, msg, fn) {
    _popup(idx, msg, fn);
    return false;
  }
  function confirmPopup(msg, fn) {
    var $shadow = $('#modalShadow'),
        $reward = $('#popYN'),
        func = fn, desc;
        desc = msg;
        _popup('이벤트', desc, func, 'confirm');  
        $shadow.one('click', function () {     
          $shadow.hide();
          $reward.hide();
          return false;
        });  
  }
  

  function changeLang(lang){
    currentLang = lang;
    $('html').attr('lang', lang);
    if(lang == 'ko') {
      $('title').html('Triple Fantasy');
    } else if (lang == 'tw') {
      $('title').html('三重幻想');
    } else if (lang == 'ja') {
      $('title').html('トリプルファンタジー');
    } else {
      $('title').html('Triple Fantasy');
    }
    
    $('[data-langnum]').each(function () {
      var a = $(this);
      a.html($.lang[lang][a.data('langnum')])
      a.attr('title',$.lang[lang][a.data("langnum")])
      a.attr('placeholder',$.lang[lang][a.data("langnum")])
    });
    // option
    // $('.select option').each(function (idx, obj){
    //   $(obj).html($.lang[lang][1+idx]);
    // })
  }

  function getLang() {
    return currentLang;
  }

  function checkDevice(){
      var UserAgent = navigator.userAgent;
      if (UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null)
      {
        return true;
      }else{
        return false;
      }
    }
  function initilize() {
    $(function(){
      if(checkDevice()){
        $('.wrap_coupon').attr('class','wrap_coupon mobile');
      }else{
        $('.wrap_coupon').attr('class','wrap_coupon pc');
      }
    });

    $(window).load(function () {
      $util.setFontSize();
    });

    $(window).resize(function () {
      $util.setFontSize();
    });

  }

  return {
    confirmPopup: confirmPopup,
    alertPopup: alertPopup,
    resultPopup: resultPopup,
    inforPopup:inforPopup,
    changeLang: changeLang,
    getLang: getLang,
    initilize: initilize
  };

}());

$ui.initilize();